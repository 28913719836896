.boardContainer{
    display: grid;
    grid-template-areas: "ad1 scoreboard mainBoard preViewBoard ad2";
    grid-template-columns: 1fr 0.4fr 1fr 0.4fr 1fr;
    gap: 1vh;

    justify-content: center;
}

.board{
    grid-area: mainBoard;
}

.preViewContainer{
    grid-area: preViewBoard;

    margin-top: -10px; /*Align Previewboard with board*/
}

.scoreboard{
    grid-area: scoreboard;
}