.popUp {
    height: 20%;
    min-height: 150px;
    min-width: 150px;
    margin: 0 auto;
    padding: 10px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    z-index: 1;
    position: absolute;
    text-align: center;

    border-radius: 5px;
    background-color: #b3b3b3d3 !important;
}

.content{
    display: flex;
    align-items: center;
    gap: 5px;
}

.popUp form{
    height: 100%;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.popUp form .button{
    width: 70%;
    height: auto;
    padding: 5px;

    border: none;
    border-radius: 5px;

    background-color: #fff;
    box-shadow: #d6d6d6 0 0px 5px;
    font-size: 12px;

}

.popUp form label{
    font-size: 12px;
}

.popUp form input{
    margin-top: 10px;
    padding: 5px;
    
    border: none;
    border-radius: 5px;    
    text-align: center;

}

