.stats{
    width: 37.5vh;
    padding: 1vh 0;

    border: 2px solid #a7a7a7;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.stat{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;    
    flex: 1 1 0px;
}

.stat
.name{
    margin: 1%;

    text-align: center;
    font-size: 9px;
    color: #5f5f5f;
}

.stat
.value{   
    margin: 1%;

    text-align: center;
    font-size: small;
}