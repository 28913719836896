.controls{
    width: 37.5vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.gamepad{
    width: 15vh;
    height: 10vh;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
                        "a . b"
                        ". c .";
    justify-items: center;
    align-items: center;
}