table{
    height: fit-content;

    font-size: 9px;
    border: 2px solid #a7a7a7;
    border-radius: 5px;
}

thead th{
    font-weight: 100;
    color: #5f5f5f;
}

tbody td{
    text-align: center;
    font-weight: 400;
}