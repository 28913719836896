.block {
    justify-self: stretch;
}

.blink {
    animation-name: animation_blink;
    animation-timing-function: ease-in;
    animation-duration: 0.4s; 
}

@keyframes animation_blink {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }    
}

.I {
    background-color: #3498db;
    border: 0.5px solid #2c82c9;
}

.J {
    background-color: #e74c3c;
    border: 1px solid #c83a2c;
}

.L {
    background-color: #2ecc71;
    border: 1px solid #26ae5f;
}

.O {
    background-color: #f39c12;
    border: 1px solid #d6890a;
}

.S {
    background-color: #9b59b6;
    border: 1px solid #874ea2;
}

.T {
    background-color: #1abc9c;
    border: 1px solid #159d7e;
}

.Z {
    background-color: #e67e22;
    border: 1px solid #b76518;
}
 
.ghost {
    opacity: 0.4;
}

.control-button {
    background-color: #c0c0c0;
    background-image: url("./svg/arrow.svg");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 50% calc(50% + 0.1pc);
}

.rotate-button {
    background-color: #c0c0c0;
    background-image: url("./svg/rotate.svg");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 50% calc(50% + 0.04pc);
}

.start-button {
    background-color: #89d17b !important;
    background-image: url("./svg/arrow.svg");
    transform: rotate(-90deg);
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 50% calc(50% + 0.04pc);
}

.stop-button {
    background-color: #d17b7b !important;
    background-image: url("./svg/pause.svg");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 50% calc(50% + 0.04pc);
}

.rapid-descent-button{
    background-color: #d2cb47 !important;
    background-image: url("./svg/arrow.svg");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 50% calc(50% + 0.04pc);
}

.button{
    height: 4vh;
    width: 4vh;
    margin: 0;

    border: 2px solid #a7a7a7;
    border-radius: 4vh;
    text-align: center;

    box-shadow: inset 0px 0 0px 1px #0000004d;
}

.button:hover{
    opacity: 78%;
}

.key{
    padding: 5px;
    background-color: #fff;
    border: #000 solid 1px;
    border-radius: 5px;
}

.key:hover{
    background-color: #ececec;
}