html,
body {
    margin: 1vh;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.container{
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 1vh;
}

h1 {
    margin: 2px;   
}

p {
    margin: 2px;
    font-size: 5px;
    font-weight: 100;
    color: #5f5f5f;
}