.mainBoard{
  --heigth: 75vh;
  --width: 37.5vh;

  --max-heigth: 100vw;
  --max-width: 50vw;
  
  --columns: 10;
  --rows: 20;

  background-image: url("./svg/mainBoardBG.svg");
  background-size: cover;
}

.preViewBoard{
  --heigth: 10vh;
  --width: 10vh;

  --max-heigth: 10vw;
  --max-width: 10vw;

  --columns: 4;
  --rows: 4;

  background-image: url("./svg/PreViewBoardBG.svg");
  background-size: cover;
}

.board{
  position: relative;
  height: var(--heigth);
  width: var(--width);

  max-height: var(--max-heigth);
  max-width: var(--max-width);

  border: 2px solid #a7a7a7;
  border-radius: 5px;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
}